import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import theme from "../../theme";

const WrapperSuggestionCard = styled.div`
    width: 23.939%;
    
    img {
        width: 100%;
        height: 170px;
        border-top-left-radius: 35px;
        border-bottom-right-radius: 35px;
        cursor: pointer;
    }

    .infos {
        margin-top: 15px;
        display: flex;

        span {
            padding: 0px 8px;
            font-size: .875rem;
        }

        .date {
            color: #acacb2;
            font-family: ${theme.fontFamily.regular};
            border-right: #acacb2 1px solid;
        }

        .theme {
            color: #da8542;
            font-family: ${theme.fontFamily.medium};
        }
    }
 
    h3 {
        padding: 0px 8px;
        margin-top: 7px;
        font-size: 1rem;
        cursor: pointer;
    }

    @media (max-width: ${theme.breackpoint.mobile_2}) {
        width: 100%;

        img {
            height: 250px;
        }
    }

    @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) and (min-width: ${theme.breackpoint.mobile_2}){
        width: 49%;
        margin-bottom: 20px;
    }

    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}){
        width: 30%;
        
    } 

    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}){

        img {
            height: 150px;
        }

        .infos {
            margin-top: 15px;
            display: flex;

            span {
                padding: 0px 8px;
                font-size: .715rem;
            }
        }
    
        h3 {
            font-size: .8rem;
        }
    } 
`

const SuggestionCard = props => {
    const navigate = useNavigate()
    const goToSuggestion = () => {
        navigate(props.link)
    }

    return <WrapperSuggestionCard>  
        <img src={props.img} alt={props.title} onClick={goToSuggestion} />
        <div className="infos">
            <span className="date">{props.date}</span>
            <span className="theme">{props.theme}</span>
        </div>
        <h3 onClick={goToSuggestion} >{props.title}</h3>
    </WrapperSuggestionCard>
}

export default SuggestionCard