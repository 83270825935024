import styled from "styled-components";
import { FaApple } from "react-icons/fa";
import { RiGooglePlayFill } from "react-icons/ri";
import theme from "../../theme";

const WrapperCardApp = styled.div`
    display: flex;
    background-color: white;
    padding: 6px 7px;
    align-self: flex-start;
    gap: 5px;
    width: calc(80% - 14px);
    margin-bottom: 10px;
    border-radius: 5px;
    color: #383838;
    font-weight: bold;
    cursor: pointer;
    user-select: none;

    .icon-container{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2.2rem;
    }

    .content {
        display: flex;
        flex-direction: column;

        .title-card {
            font-size: .65rem;
        }

        .store-name {
            font-size: 1.2rem;
            line-height: 1.1rem;
        }
    }

    @media (max-width: ${theme.breackpoint.mobile_2}) {
        align-self: center;
        width: calc(49% - 14px);

    }
`

const CardApp = (props) => {
    const storeName = props.store === "App Store" ? "App Store" : "Google Play"
    const redirectToStore = () => window.location.href = props.link

    return <WrapperCardApp onClick={redirectToStore}>
        <div className="icon-container">
            {storeName === "App Store" ?
                <FaApple /> :
                <RiGooglePlayFill />
            }
        </div>
        <div className="content">
            <span className="title-card">Disponível na</span>
            <span className="store-name">{storeName}</span>
        </div>
    </WrapperCardApp>
}

export default CardApp