import { useContext, useEffect, useRef, useState } from "react";
import WrapperSearch from "./Search-styles";
import { IoIosArrowDown } from "react-icons/io";
import Property from "../../components/property/Property";
import Img from "../../imgs/low-angle-shot-facade-white-modern-building-blue-clear-sky.jpg"
import { useLocation } from "react-router-dom";
import MyMapComponent from "../../components/map/Map";
import { DiVim } from "react-icons/di";
import { db } from "../../hooks/firebase";
import { collection, getDocs, query, where } from 'firebase/firestore';

import { LuX } from "react-icons/lu";
import theme from "../../theme";
import { AuthContext } from "../../context/AuthContext";

const SearchPage = () => {
    //const {userData} = useContext(AuthContext)
    //console.log(userData)
    const mobileWidth = theme.breackpoint.mobile_2.replace("px", "")
    const isMobile = window.innerWidth <= parseInt(mobileWidth)
    const [adressLocation, setAdressLocation] = useState("")
    const [furnitured, setFurnitured] = useState(undefined)
    const [pool, setPool] = useState(undefined)
    const [balcony, setBalcony] = useState(undefined)
    const [pets, setPets] = useState(undefined)
    const [bathroomQuantity, setBathroomQuantity] = useState(undefined)
    const [bedroomQuantity, setBedroomQuantity] = useState(undefined)
    const [garageQuantity, setGarageQuantity] = useState(undefined)
    const [minValue, setMinValue] = useState(undefined)
    const [maxValue, setMaxValue] = useState(undefined)
    const [minMeter, setMinMeter] = useState(undefined)
    const [maxMeter, setMaxMeter] = useState(undefined)
    const [showFiltersMobile, setShowFiltersMobile] = useState(false)
    const location = useLocation()
    const { city, type } = location.state || {}; 
    const typesOfProperties = [
        {name: "Todos os imóveis", active: false},
        {name: "Apartamento", active: false},
        {name: "Área Comercial", active: false},
        {name: "Casa", active: false},
        {name: "Casa Comercial", active: false},
        {name: "Casa Em Condomínio", active: false},
        {name: "Chácara", active: false},
        {name: "Galpão/Armazém", active: false},
        {name: "Kitnet/Studio", active: false},
        {name: "Prédio Comercial", active: false},
        {name: "Rancho", active: false},
        {name: "Sala Comercial", active: false},
        {name: "Salão Comercial", active: false},
        {name: "Sítio/Fazenda", active: false},
        {name: "Terreno", active: false},
        {name: "Terreno Comercial", active: false},
        {name: "Terreno Em Condomínio", active: false},
    ]
    const filters2 = [
        {name: "Todos os imóveis", active: false},
        { active: false, name: "Academia" },
        { active: false, name: "Aceita Pets" },
        { active: false, name: "Churrasqueira" },
        { active: false, name: "Piscina Aquecida" },
        { active: false, name: "Alarme" },
        { active: false, name: "Closet" },
        { active: false, name: "Portão Elétrico" },
        { active: false, name: "Aquecedor Solar" },
        { active: false, name: "Cozinha Planejada" },
        { active: false, name: "Sala de Jantar" },
        { active: false, name: "Ar Condicionado" },
        { active: false, name: "Elevador" },
        { active: false, name: "Varanda" },
        { active: false, name: "Área Gourmet" },
        { active: false, name: "Energia Solar" },
        { active: false, name: "Mobiliado" },
        { active: false, name: "Área De Serviço" },
        { active: false, name: "Escritório" },
        { active: false, name: "Segurança 24h" },
        { active: false, name: "Armário Embutido" },
        { active: false, name: "Home" },
        { active: false, name: "Banheira" },
        { active: false, name: "Interfone" },
        { active: false, name: "Lavabo" },
        { active: false, name: "Câmeras de Segurança" },
        { active: false, name: "Lavanderia" },
        { active: false, name: "Sala de Tv" },
        { active: false, name: "Cerca Elétrica" },
        { active: false, name: "Piscina" },
    ]
    const [data, setData] = useState(null)
    const [filteredData, setFilteredData] = useState(null)
    const [showFilters, setShowFilters] = useState(false)
    const [showFilters2, setShowFilters2] = useState(false)
    const [typeOfProperty, setTypeOfProperty] = useState(() => {
        return typesOfProperties.map(item => {
            const isActive = type ? item.name === type : item.name === "Todos os imóveis";
            return {
                ...item,
                active: isActive
            };
        });
    });
    const [typeOfProperty2, setTypeOfProperty2] = useState(() => {
        return filters2.map(item => {
            const isActive = type ? item.name === type : item.name === "Todos os imóveis";
            return {
                ...item,
                active: isActive
            };
        });
    });

    useEffect(() => {
        
        if (data === null) {
            const scrollToTop = () => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth' // Para um efeito de scroll suave
                });
            };
    
            scrollToTop();
            const propertiesCollection = collection(db, 'properties');
            const activePropertiesQuery = query(
                propertiesCollection, 
                where('active', '==', true),
                where('status', "==", "ACTIVE")
            
            );
    
            getDocs(activePropertiesQuery).then(result => {
                const propertiesList = result.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                
                setData(propertiesList);
                setFilteredData(propertiesList);
                setAdressLocation(city);
            });
        } else {
            const filtered = filterProperty(data);
            setFilteredData(filtered);
        }
    }, [
        typeOfProperty,
        typeOfProperty2,
        adressLocation,
        furnitured,
        pool,
        balcony,
        pets,
        bathroomQuantity,
        bedroomQuantity,
        garageQuantity,
        minValue,
        maxValue,
        minMeter,
        maxMeter
    ]);

    function filterProperty(data) {
        const filters = {
            adressLocation: adressLocation ? adressLocation.toLowerCase() : "",
            furnitured,
            pool,
            balcony,
            pets,
            bathroomQuantity,
            bedroomQuantity,
            garageQuantity,
            minValue,
            maxValue,
            minMeter,
            maxMeter,
            selectedType: typeOfProperty.find(item => item.active)?.name || "Todos os imóveis", // Captura o tipo selecionado
            selectedType2: typeOfProperty2.find(item => item.active)?.name || "Todos os imóveis" // Captura o tipo selecionado
        };
    
        const searchTerms = filters.adressLocation.split(' ').filter(term => term.trim() !== '');
    
        return data.filter(property => {
            let isMatched = true;
            let normalizedOtherItems = property.otherItems.map(item => {
                return item.label
            })


            if (filters.selectedType !== "Todos os imóveis" && filters.selectedType.toLowerCase() !== property.type.toLowerCase()) {
                isMatched = false;
            }

            if (filters.selectedType2 !== "Todos os imóveis" && !normalizedOtherItems.includes(filters.selectedType2)) {
                isMatched = false
            }
    
            const isAddressMatched = searchTerms.every(term => 
                property.street.toLowerCase().includes(term) ||
                property.neightboard.toLowerCase().includes(term) ||
                property.city.toLowerCase().includes(term) ||
                property.state.toLowerCase().includes(term)
            );
    
            if (!isAddressMatched) {
                isMatched = false;
            }
    
            // Verificação de outros filtros
            if (filters.furnitured !== undefined && filters.furnitured != property.furnitured) {
                isMatched = false;
            }
    
            if (filters.pets !== undefined && filters.pets != property.pets) {
                isMatched = false;
            }
    
            if (filters.pool !== undefined && filters.pool != property.pool) {
                isMatched = false;
            }
    
            if (filters.balcony !== undefined && filters.balcony != property.balcony) {
                isMatched = false;
            }
    
            if (filters.minMeter !== undefined && property.squareMeter < filters.minMeter) {
                isMatched = false;
            }
    
            if (filters.maxMeter !== undefined && property.squareMeter > filters.maxMeter) {
                isMatched = false;
            }
    
            if (filters.bedroomQuantity !== undefined && filters.bedroomQuantity != property.bedroom) {
                isMatched = false;
            }
    
            if (filters.bathroomQuantity !== undefined && filters.bathroomQuantity != property.bathroom) {
                isMatched = false;
            }
    
            if (filters.garageQuantity !== undefined && filters.garageQuantity != property.garage) {
                isMatched = false;
            }
    
            if (filters.minValue !== undefined && property.price < filters.minValue) {
                isMatched = false;
            }
    
            if (filters.maxValue !== undefined && property.price > filters.maxValue) {
                isMatched = false;
            }
    
            return isMatched;
        });
    }
    
    function handleAdress(event) {
        let value = event.target.value.toLowerCase()
        setAdressLocation(value)
    }

    function handleTypeOfProperty(itemToUpdate) {
        setTypeOfProperty(prevState => 
            prevState.map(item => 
                item.name === itemToUpdate.name 
                    ? { ...item, active: !item.active } 
                    : item
            )
        );
    }

    function handleTypeOfProperty2(itemToUpdate) {
        setTypeOfProperty2(prevState => 
            prevState.map(item => 
                item.name === itemToUpdate.name 
                    ? { ...item, active: !item.active } 
                    : item
            )
        );
    }

    function toggleFurnituredFilter(e) {
        setFurnitured(furnitured === true ? undefined : true)
        e.target.classList.toggle("active")
    }

    function togglePoolFilter(e){
        setPool(pool === true ? undefined : true)
        e.target.classList.toggle("active")
    }

    function toggleBalconyFilter(e){
        setBalcony(balcony === true ? undefined : true)
        e.target.classList.toggle("active")
    }

    function togglePetsFilter(e){
        setPets(pets === true ? undefined : true)
        e.target.classList.toggle("active")
    }

    function setFilterBathroomQuantity(value, e) {
        let parent = e.target.parentElement;
        let buttons = parent.querySelectorAll('.button');   

        if (e.target.classList.contains("active")) {
            setBathroomQuantity(undefined)

            buttons.forEach(button => {
                button.classList.remove('active');
            });

            return;
        }

        buttons.forEach(button => {
            button.classList.remove('active');
        });

        e.target.classList.add('active');
        setBathroomQuantity(value)
    }

    function setFilterBedroomQuantity(value, e) {
        let parent = e.target.parentElement;
        let buttons = parent.querySelectorAll('.button');

        if (e.target.classList.contains("active")) {
            setBedroomQuantity(undefined)

            buttons.forEach(button => {
                button.classList.remove('active');
            });

            return;
        }

        buttons.forEach(button => {
            button.classList.remove('active');
        });

        e.target.classList.add('active');
        setBedroomQuantity(value)
    }

    function setFilterGarageQuantity(value, e) {
        let parent = e.target.parentElement;
        let buttons = parent.querySelectorAll('.button');

        if (e.target.classList.contains("active")) {
            setGarageQuantity(undefined)

            buttons.forEach(button => {
                button.classList.remove('active');
            });

            return;
        }

        buttons.forEach(button => {
            button.classList.remove('active');
        });

        e.target.classList.add('active');
        setGarageQuantity(value)
    }

    return <WrapperSearch>
        <div className={`filters-mobile-menu${showFiltersMobile ? " show" : ""}`}>
            <div className="top">
                Filtros

                <LuX onClick={() => setShowFiltersMobile(!showFiltersMobile)} />
            </div>
            <div className="filters">
                <div className="property-filter">
                    <span className="title">
                        Tipo de imóvel:
                    </span>
                    <div className="filter-selectbox-top"  onClick={() => setShowFilters(!showFilters)}>
                        <div className="square"></div>
                        <span className="title">Filtros</span>
                        <IoIosArrowDown />
                    </div>
                    <div className={`filter-selectbox ${showFilters ? "show": ""}`} onBlur={() => setShowFilters(false)} tabIndex={0}>
                        {typeOfProperty.map((item, index) => {
                            return <div onClick={() => handleTypeOfProperty(item)} className="selectbox-option" key={`${item.name}+${index}`}>
                                <div className={`square ${item.active ? "active" : ""}`}></div>
                                <span className="option">{item.name}</span>
                            </div>
                        })}
                    </div>
                </div>
                <div className="property-filter">
                    <span className="title">
                        Refine sua busca:
                    </span>
                    <div className="filter-selectbox-top" onClick={() => setShowFilters2(!showFilters2)}>
                        <div className="square"></div>
                        <span className="title">Filtros</span>
                        <IoIosArrowDown />
                    </div>
                    <div className={`filter-selectbox ${showFilters2 ? "show": ""}`} onBlur={() => setShowFilters(false)} tabIndex={0}>
                        {typeOfProperty.map((item, index) => {
                            return <div onClick={() => handleTypeOfProperty(item)} className="selectbox-option" key={`${item.name}+${index}`}>
                                <div className={`square ${item.active ? "active" : ""}`}></div>
                                <span className="option">{item.name}</span>
                            </div>
                        })}
                    </div>
                </div>
                <div className="property-filter">
                    <span className="title">
                        Preço:
                    </span>
                    <div className="inputs">
                        <div className="input-container">
                            <span className="title">Mínimo</span>
                            <div className="input-box">
                                <span className="prefix">R$</span>
                                <input onChange={(input) => setMinValue(parseInt(input.target.value))} type="text" />
                            </div>
                        </div>
                        <div className="input-container">
                            <span className="title">Máximo</span>
                            <div className="input-box">
                                <span className="prefix">R$</span>
                                <input type="text" onChange={(input) => setMaxValue(parseInt(input.target.value))} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="property-filter">
                    <span className="title">
                        Área do imóvel:
                    </span>
                    <div className="inputs">
                        <div className="input-container">
                            <span className="title">Mínimo</span>
                            <div className="input-box">
                                <input onChange={(event) => setMinMeter(parseInt(event.target.value))} type="text" />
                                <span className="prefix">m²</span>
                            </div>
                        </div>
                        <div className="input-container">
                            <span className="title">Máximo</span>
                            <div className="input-box">
                                <input onChange={(event) => setMaxMeter(parseInt(event.target.value))} type="text" />
                                <span className="prefix">m²</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="property-filter">
                    <span className="title">Quartos</span>
                    <div className="property-opts">
                        <span className="button" onClick={(e) => setFilterBedroomQuantity('1', e)}>1</span>
                        <span className="button" onClick={(e) => setFilterBedroomQuantity('2', e)}>2</span>
                        <span className="button" onClick={(e) => setFilterBedroomQuantity('3', e)}>3</span>
                        <span className="button" onClick={(e) => setFilterBedroomQuantity('4', e)}>4</span>
                        <span className="button" onClick={(e) => setFilterBedroomQuantity('4+', e)}>4+</span>
                    </div>
                </div>
                <div className="property-filter">
                    <span className="title">Banheiro</span>
                    <div className="property-opts">
                        <span className="button" onClick={(e) => setFilterBathroomQuantity('1', e)}>1</span>
                        <span className="button" onClick={(e) => setFilterBathroomQuantity('2', e)}>2</span>
                        <span className="button" onClick={(e) => setFilterBathroomQuantity('3', e)}>3</span>
                        <span className="button" onClick={(e) => setFilterBathroomQuantity('4', e)}>4</span>
                        <span className="button" onClick={(e) => setFilterBathroomQuantity('4+', e)}>4+</span>
                    </div>
                </div>
                <div className="property-filter">
                    <span className="title">Vaga de Carro</span>
                    <div className="property-opts">
                        <span className="button" onClick={(e) => setFilterGarageQuantity('1', e)}>1</span>
                        <span className="button" onClick={(e) => setFilterGarageQuantity('2', e)}>2</span>
                        <span className="button" onClick={(e) => setFilterGarageQuantity('3', e)}>3</span>
                        <span className="button" onClick={(e) => setFilterGarageQuantity('4', e)}>4</span>
                        <span className="button" onClick={(e) => setFilterGarageQuantity('4+', e)}>4+</span>
                    </div>
                </div>
            </div>
        </div>
        <div className={`layer-filters${showFiltersMobile ? " show" : ""}`}>
        </div>
        
        <div className="property-filters">
            <div className="property-filters-top">
                {isMobile && 
                    <span className="filters-mobile" onClick={() => setShowFiltersMobile(!showFiltersMobile     )}>
                        Filtros
                    </span>
                }
                <input type="text" placeholder="Digite o nome da rua, bairro ou cidade" value={adressLocation} onChange={handleAdress} />
                {/* <span className="property-filter" onClick={toggleFurnituredFilter}>Mobiliado</span>
                <span className="property-filter" onClick={togglePoolFilter}>Piscina</span>
                <span className="property-filter" onClick={toggleBalconyFilter}>Varanda</span>
                <span className="property-filter" onClick={togglePetsFilter}>Aceita Pets</span> */}
            </div>
            <div className="property-filters-bottom">
                <div className="property-filter">
                    <span className="title">
                        Tipo de imóvel:
                    </span>
                    <div className="filter-selectbox-top"  onClick={() => setShowFilters(!showFilters)}>
                        <div className="square"></div>
                        <span className="title">Filtros</span>
                        <IoIosArrowDown />
                    </div>
                    <div className={`filter-selectbox ${showFilters ? "show": ""}`} onBlur={() => setShowFilters(false)} tabIndex={0}>
                        {typeOfProperty.map((item, index) => {
                            return <div onClick={() => handleTypeOfProperty(item)} className="selectbox-option" key={`${item.name}+${index}`}>
                                <div className={`square ${item.active ? "active" : ""}`}></div>
                                <span className="option">{item.name}</span>
                            </div>
                        })}
                    </div>
                </div>
                <div className="property-filter">
                    <span className="title">
                        Refíne sua busca:
                    </span>
                    <div className="filter-selectbox-top" onClick={() => setShowFilters2(!showFilters2)}>
                        <div className="square"></div>
                        <span className="title">Filtros</span>
                        <IoIosArrowDown />
                    </div>
                    <div className={`filter-selectbox ${showFilters2 ? "show": ""}`} onBlur={() => setShowFilters2(false)} tabIndex={0}>
                        {typeOfProperty2.map((item, index) => {
                            return <div onClick={() => handleTypeOfProperty2(item)} className="selectbox-option" key={`${item.name}+${index}`}>
                                <div className={`square ${item.active ? "active" : ""}`}></div>
                                <span className="option">{item.name}</span>
                            </div>
                        })}
                    </div>
                </div>
                <div className="property-filter">
                    <span className="title">
                        Preço:
                    </span>
                    <div className="inputs">
                        <div className="input-container">
                            <span className="title">Mínimo</span>
                            <div className="input-box">
                                <span className="prefix">R$</span>
                                <input onChange={(input) => setMinValue(parseInt(input.target.value))} type="text" />
                            </div>
                        </div>
                        <div className="input-container">
                            <span className="title">Máximo</span>
                            <div className="input-box">
                                <span className="prefix">R$</span>
                                <input type="text" onChange={(input) => setMaxValue(parseInt(input.target.value))} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="property-filter">
                    <span className="title">
                        Área do imóvel:
                    </span>
                    <div className="inputs">
                        <div className="input-container">
                            <span className="title">Mínimo</span>
                            <div className="input-box">
                                <input onChange={(event) => setMinMeter(parseInt(event.target.value))} type="text" />
                                <span className="prefix">m²</span>
                            </div>
                        </div>
                        <div className="input-container">
                            <span className="title">Máximo</span>
                            <div className="input-box">
                                <input onChange={(event) => setMaxMeter(parseInt(event.target.value))} type="text" />
                                <span className="prefix">m²</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="property-filter">
                    <span className="title">Banheiro</span>
                    <div className="property-opts">
                        <span className="button" onClick={(e) => setFilterBathroomQuantity('1', e)}>1</span>
                        <span className="button" onClick={(e) => setFilterBathroomQuantity('2', e)}>2</span>
                        <span className="button" onClick={(e) => setFilterBathroomQuantity('3', e)}>3</span>
                        <span className="button" onClick={(e) => setFilterBathroomQuantity('4', e)}>4</span>
                        <span className="button" onClick={(e) => setFilterBathroomQuantity('4+', e)}>4+</span>
                    </div>
                </div>
                <div className="property-filter">
                    <span className="title">Quartos</span>
                    <div className="property-opts">
                        <span className="button" onClick={(e) => setFilterBedroomQuantity('1', e)}>1</span>
                        <span className="button" onClick={(e) => setFilterBedroomQuantity('2', e)}>2</span>
                        <span className="button" onClick={(e) => setFilterBedroomQuantity('3', e)}>3</span>
                        <span className="button" onClick={(e) => setFilterBedroomQuantity('4', e)}>4</span>
                        <span className="button" onClick={(e) => setFilterBedroomQuantity('4+', e)}>4+</span>
                    </div>
                </div>
                <div className="property-filter">
                    <span className="title">Vaga de Carro</span>
                    <div className="property-opts">
                        <span className="button" onClick={(e) => setFilterGarageQuantity('1', e)}>1</span>
                        <span className="button" onClick={(e) => setFilterGarageQuantity('2', e)}>2</span>
                        <span className="button" onClick={(e) => setFilterGarageQuantity('3', e)}>3</span>
                        <span className="button" onClick={(e) => setFilterGarageQuantity('4', e)}>4</span>
                        <span className="button" onClick={(e) => setFilterGarageQuantity('4+', e)}>4+</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="properties">
            {data !== null && 
                filteredData.map((item, index) => {
                    return <Property 
                        data={item} 
                        key={`${item.username}+${index}`} 
                    />
                })
            }
        </div>
        <div className="map">
            <MyMapComponent properties={filteredData} />
        </div>
    </WrapperSearch>
}

export default SearchPage