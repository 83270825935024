import WrapperMostSearched from "./MostSearched-styles";
import Casa from "../../imgs/categories/CASA.png"
import CasaComercial from "../../imgs/categories/CASA COMERCIAL.png"
import Chacara from "../../imgs/categories/CHÁCARA.png"
import Comercio from "../../imgs/categories/COMÉRCIO.png"
import Apartamento from "../../imgs/categories/APARTAMENTO.png"
import Terreno from "../../imgs/categories/TERRENO.png"
import CarouselTypeB from './../slide_show_type_b/SlideShowTypeB';
import theme from "../../theme";
import { useEffect, useState } from "react";

const MostSearched = () => {
    const cards = [
        {img: Casa, link: "/buscador", type: "Casa"},
        {img: Comercio, link: "/buscador", type: "Comercio"},
        {img: CasaComercial, link: "/buscador", type: "CasaComercial"},
        {img: Chacara, link: "/buscador", type: "Chacara"},
        {img: Apartamento, link: "/buscador", type: "Apartamento"},
        // {img: Terreno, link: "/buscador", type: "Terreno"},
    ]
    const [slidesPerView, setSlidesPerView] = useState(4);

    useEffect(() => {
        const updateSlidesPerView = () => {
            const windowWidth = window.innerWidth;
            if (windowWidth <= theme.breackpoint.mobile_2.replace("px", "")) {
                setSlidesPerView(1)
            } else if (windowWidth <= theme.breackpoint.tabletsAndSmallScreens.replace("px", "")) {
                setSlidesPerView(2)
            } else {
                setSlidesPerView(4)
            }

        };

        updateSlidesPerView();

        window.addEventListener('resize', updateSlidesPerView);

        return () => {
            window.removeEventListener('resize', updateSlidesPerView);
        };
    }, []);

 
    return <WrapperMostSearched>
        <h1 className="title">Mais procurados por categoria:</h1>
        <div className="container">
            {cards !== null &&
                <CarouselTypeB 
                    visibleItems={slidesPerView}
                    items={cards.map((categorie) => ({
                        img: categorie.img,
                        link: categorie.link,
                        type: categorie.type
                    }))}
                />
            }
        </div>
    </WrapperMostSearched>
}

export default MostSearched