const theme = {
    fontFamily: {
        regular: "Nunito-regular",
        light: "Nunito-light",
        extraLight: "Nunito-extralight",
        semiBold: "Nunito-semibold",
        medium: "Nunito-medium",
        bold: "Nunito-bold",
        extraBold: "Nunito-extrabold",
    }, 
    fontColor: {
       black: "#111111",
       black2: "#222222",
       black3: "#333333",
       white: "#ffffff",
       extraWhite: "#989697",
       green: "#08bbb5",    
       purple: "#502dc5",
       orange: "#fe8f1b",
       gray: "#b3afad"
    },
    backgroundColor: {
        green: "#08bbb5",
        green2: "#01b901",
        green3: "#009792",  
        green4: "#e5fffe",
        purple: "#502dc5",
        black: "#111111",
        black2: "#222222",
        black3: "#333333",
        black4: "#444444",
        black5: "#555555",
        black6: "#666666",
        black7: "#777777",
        black8: "#777777",
        black9: "#999999",
        extraWhite: "#989697"
    },
    breackpoint: {
        mobile_2: "640px",
        tabletsAndSmallScreens: "1020px",
        commonScreensAndLaptops: "1270px",
        largeScreens: "1600px",
        bigScreens: "1900px"
    }
}

export default theme