import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { clearViewHistory, getPropertiesViewedByUser } from "../../hooks/firebase";
import MyProperty from "../my-property/MyProperty";
import WrapperMyData from "../../views/my-data/MyData.styles";
import NavBar from "../nav-bar/NavBar";
import { NotificationContext } from "../../context/NotificationContext";
import { useQuery, useQueryClient, useIsFetching } from "@tanstack/react-query";
import { FaChevronLeft } from 'react-icons/fa';
import { FaChevronRight } from 'react-icons/fa';
const PAGE_SIZE = 10; // Número de itens por página

const LastViewed = () => {  
    const [searchContext, setSearchContext] = useState(""); 
    const [page, setPage] = useState(1);
    const { currentUser } = useContext(AuthContext);
    const { addNotification } = useContext(NotificationContext);
    const queryClient = useQueryClient();
    const containerRef = useRef(null); 

    const handleClearHistory = async () => {
        const result = await clearViewHistory(currentUser);
        if (result) {
            queryClient.invalidateQueries(['lastViewed_term', currentUser]);
            addNotification("Histórico limpo", "info");
        }
    };

    const handleKeyDown = (event) => {
        setSearchContext(event.target.value.toLowerCase());
        //setPage(1);
    };

    const useFetchQuery = (userId, page) => {
        return useQuery({
            queryKey: ['lastViewed_term', userId, page], 
            queryFn: () => getPropertiesViewedByUser(userId, page, PAGE_SIZE),
            keepPreviousData: true, 
            staleTime: 15 * 60 * 1000, 
            refetchOnWindowFocus: false,
            retry: false,
            cacheTime: 15 * 60 * 1000,
        });
    };

    const { data = { properties: [], total: 0 }, isLoading, error } = useFetchQuery(currentUser, page);
    const { properties, total } = data;
    const totalPages = Math.ceil(total / PAGE_SIZE);

    const filteredProperties = properties.filter((property) =>
        searchContext.split(" ").every((term) =>
            (property.street && property.street.toLowerCase().includes(term)) ||
            (property.number && property.number.toLowerCase().includes(term)) ||
            (property.neighborhood && property.neighborhood.toLowerCase().includes(term)) ||
            (property.city && property.city.toLowerCase().includes(term)) ||
            (property.state && property.state.toLowerCase().includes(term))
        )
    );

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    }, [page]); 

    return (
        <WrapperMyData nothing={properties.length === 0}>
            <NavBar />
            <div className="container-favorites" ref={containerRef}>
                {isLoading && <div>Carregando...</div>}
                {error && <div>Ocorreu um erro ao carregar os imóveis.</div>}

                {properties.length >= 1 && (
                    <>
                        <div className="search-box">
                            <span className="title">Buscar</span>
                            <span className="clear-history" onClick={handleClearHistory}>
                                Limpar Histórico
                            </span>
                            <input
                                type="text"
                                onChange={handleKeyDown}
                                placeholder="Ex: Condomínio Gaivota 2"
                            />
                        </div>
                        <div className="properties">
                            {filteredProperties.map((property, index) => (
                                <MyProperty
                                    key={index + "property-element"}
                                    data={{ ...property, currentUser }}
                                    hidden={true}
                                    viewed={true}
                                />
                            ))}
                        </div>

                        {/* Paginação */}
                        <div className="pagination">
                            <button
                                onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                                disabled={page === 1}
                            >
                                <FaChevronLeft />
                            </button>
                            <span>
                                {page} de {totalPages}
                            </span>
                            <button
                                onClick={() => setPage((prev) => (prev < totalPages ? prev + 1 : prev))}
                                disabled={page === totalPages}
                            >
                                <FaChevronRight />
                            </button>
                        </div>
                    </>
                )}

                {properties.length === 0 && (
                    <div>
                        <span className="nothing-favorites">
                            Nenhum imóvel no histórico, continue explorando o nosso site em busca do seu futuro lar dos sonhos :)
                        </span>
                    </div>
                )}
            </div>
        </WrapperMyData>
    );
};

export default LastViewed;
