
export const encriptCard = (data) => {
    const card = window.PagSeguro.encryptCard({
        publicKey: "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAo1ZeRNbiFfdNFCil7OdHSTbj8wXE1prJPLjs58cqO9lfllGADj42ce8AuSMzvD4km5UEYpi1UQ1IYdmIWaKDUoU53zlRz3p++Dr/klAL/nm36M53Yl6n472gAPwhL5Fm7rnh2SqAdFnAeiL05dxeIcVb122lwhM27gID81OpTxhbtELtFb2qQMR1tKhH2ueSabbHIyjuzfrzRvnEeMCs6P/hq5Ta0lFnFZAyWOiZQPw/zRL0bdEyegUzmui352ervE5U/lAkIDfxsp3XYejoxy0DJjBxsIkJGhvOekydoq5XMRCyvE8AJgktbvJJLuXBZHt3uo+9QG8xozb5YxGH0wIDAQAB",
        holder: data.cardName,
        number: data.cardNumber,
        expMonth: data.expMonth,
        expYear: data.expYear,
        securityCode: data.securityCode
    })

    const encrypted = card.encryptedCard;
    const hasErrors = card.hasErrors;
    const errors = card.errors;

    return [encrypted, hasErrors]
}