import React from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import styled from "styled-components";
import MarkerPNG from "../../imgs/SIMBOLOGIA LOGO ALUGUE VERMELHO.png";

const mapContainerStyle = {
  height: "100%",
  width: "100%",
};

const WrapperMap = styled.div`
  margin-top: 20px;
  height: 50vh;
  width: 100%;
`;

const MapProperty = ({ property }) => {
  if (!property || !property.coords || !property.price) {
    return <div>Dados do imóvel não disponíveis.</div>;
  }

  return (
    <WrapperMap>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={{
          lat: property.coords.latitude,
          lng: property.coords.longitude,
        }}
        zoom={14}
      >
        <Marker
          position={{
            lat: property.coords.latitude,
            lng: property.coords.longitude,
          }}
          icon={{
            url: MarkerPNG, // Caminho da imagem importada
            scaledSize: { width: 40, height: 40 }, // Ajusta o tamanho do ícone
            anchor: { x: 20, y: 40 }, // Define o ponto de ancoragem (opcional)
          }}
          title={property.name}
        />
      </GoogleMap>
    </WrapperMap>
  );
};

export default MapProperty;
