// EXCS.js

const cacheDataWithExpiration = async (cacheKey, fetchDataFn, ttl = 3 * 60 * 60 * 1000) => {
    try {
        const cachedData = localStorage.getItem(cacheKey);
        const cachedTime = localStorage.getItem(`${cacheKey}Time`);
        const cachedHash = localStorage.getItem(`${cacheKey}Hash`);
        const now = Date.now();

        if (cachedData && cachedTime && (now - cachedTime) < ttl) {
            console.log("Usando dados do cache");

            // LOG: Verificar o hash do cache
            console.log("Hash armazenado no cache:", cachedHash);

            // Verifica se o hash está correto. Se não, invalida o cache
            const currentHash = await generateCurrentDataHash(JSON.parse(cachedData)); // Espera o hash ser gerado
            console.log("Hash gerado dos dados do cache:", currentHash); // LOG para verificação

            if (cachedHash !== currentHash) {
                console.log("Dados alterados, invalidando cache.");
                localStorage.removeItem(cacheKey);
                localStorage.removeItem(`${cacheKey}Time`);
                localStorage.removeItem(`${cacheKey}Hash`);
                return fetchDataFn().then(result => {
                    updateCache(cacheKey, result);
                    return result;
                });
            }

            return Promise.resolve(JSON.parse(cachedData));
        }

        return fetchDataFn().then(result => {
            updateCache(cacheKey, result);
            return result;
        });
    } catch {
        return fetchDataFn().then(result => {
            return result;
        });
    }
};

const cachePropertyData = async (propertyId, fetchPropertyFn, ttl = 15 * 60 * 1000) => {
    try {
        const cacheKey = `property_${propertyId}`;
        const cachedData = localStorage.getItem(cacheKey);
        const cachedTime = localStorage.getItem(`${cacheKey}Time`);
        const cachedHash = localStorage.getItem(`${cacheKey}Hash`);
        const now = Date.now();

        // Verifica se os dados estão no cache e não expiraram
        if (cachedData && cachedTime && (now - cachedTime) < ttl) {
            console.log(`Usando dados do cache para o imóvel: ${propertyId}`);

            // LOG: Verificar o hash do cache
            console.log("Hash armazenado no cache:", cachedHash);

            // Verifica se o hash está correto. Se não, invalida o cache
            const currentHash = await generateCurrentDataHash(JSON.parse(cachedData)); // Espera o hash ser gerado
            console.log("Hash gerado dos dados do cache:", currentHash); // LOG para verificação

            if (cachedHash !== currentHash) {
                console.log(`Dados do imóvel ${propertyId} alterados, invalidando cache.`);
                localStorage.removeItem(cacheKey);
                localStorage.removeItem(`${cacheKey}Time`);
                localStorage.removeItem(`${cacheKey}Hash`);
                return fetchPropertyFn().then(result => {
                    updateCache(cacheKey, result);
                    return result;
                });
            }

            return Promise.resolve(JSON.parse(cachedData));
        }

        // Caso os dados não estejam no cache ou tenham expirado, buscar os dados
        return fetchPropertyFn().then(result => {
            updateCache(cacheKey, result);
            return result;
        });
    } catch {
        return fetchPropertyFn().then(result => {
            return result;
        });
    }
};

const clearCacheOfProperty = (id) => {
    localStorage.removeItem(`property_${id}`);
    localStorage.removeItem(`property_${id}Time`);
    localStorage.removeItem(`property_${id}Hash`);
}

const updateCache = async (cacheKey, data) => {
    const now = Date.now();
    localStorage.setItem(cacheKey, JSON.stringify(data)); // Armazenar os dados no cache
    localStorage.setItem(`${cacheKey}Time`, now.toString()); // Armazenar o timestamp

    const hash = await generateCurrentDataHash(data); // Gerar o hash com base nos dados
    console.log("Hash gerado para dados novos:", hash); // LOG para verificação
    localStorage.setItem(`${cacheKey}Hash`, hash); // Armazenar o hash
    console.log("Cache atualizado com novos dados");
};

const generateCurrentDataHash = async (data) => {
    // Gerar o hash dos dados para garantir consistência
    const dataToHash = JSON.stringify(data); // Certifique-se de gerar o hash com base nos dados exatos
    return await calculateSHA256(dataToHash); // Espera o hash ser calculado
};

const calculateSHA256 = async (data) => {
    const encoder = new TextEncoder();
    const dataBuffer = encoder.encode(data); // Converte a string para um ArrayBuffer
    const hashBuffer = await crypto.subtle.digest('SHA-256', dataBuffer); // Calcula o hash
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // Converte o hash para um array de bytes
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join(''); // Converte para uma string hexadecimal
    return hashHex;
};

export { cacheDataWithExpiration, cachePropertyData, clearCacheOfProperty };
