import React, { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../hooks/firebase";
import fetchUserData from "../hooks/getUserData";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [pending, setPending] = useState(true);

  const reloadUserData = async () => {
    if (currentUser) {
      try {
        const result = await fetchUserData(currentUser);
        setUserData(result);  // Atualiza os dados do usuário no estado
      } catch (error) {
        console.error("Erro ao atualizar os dados do usuário:", error);
      }
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user.uid);
        // Carregar dados do usuário apenas se ainda não estiverem carregados
        if (!userData) {
          try {
            const result = await fetchUserData(user.uid);
            setUserData(result);
          } catch (error) {
            console.error(error);
          }
        }
      } else {
        setCurrentUser(null);
        setUserData(null);
      }
      setPending(false);
    });

    return () => unsubscribe();
  }, [userData]); // Dependência em userData para não recarregar dados repetidamente

  if (pending) {
    return <></>;
  }

  return (
    <AuthContext.Provider value={{ currentUser, userData, setUserData, reloadUserData }}>
      {children}
    </AuthContext.Provider>
  );
};
