import { useParams } from "react-router-dom";
import WrapperBlog from "./Blog.styles";
import React from "react";
import SuggestionCardB from "../../components/suggestion_card_b/SuggestionCardB";
import { getAllBlogPosts } from "../../hooks/firebase";
import { useQuery } from "@tanstack/react-query";

const Blog = () => {
    const { slug } = useParams();
    const { data: allPosts, isLoading, error } = useQuery({
        queryKey: ["blogPostsTerm"],
        queryFn: getAllBlogPosts,
        staleTime: 60 * 60 * 1000,
        cacheTime: 60 * 60 * 1000,
        refetchOnWindowFocus: false,
        retry: false,
    });

    const data = allPosts?.find(item => item.slug === slug) || null;
    const anotherBlogs = allPosts?.filter(item => item.slug !== slug) || [];

    if (isLoading) return <WrapperBlog>Carregando...</WrapperBlog>;
    if (error) return <WrapperBlog>Ocorreu um erro ao carregar os dados.</WrapperBlog>;

    return (
        <WrapperBlog>
            {data !== null && (
                <>
                    <div className="post-metadata">
                        <div className="location">
                            <span>Home</span>
                            <span>{">>"}</span>
                            <span>{data.theme}</span>
                            <span>{">>"}</span>
                            <span>{data.title}</span>
                        </div>
                        <div className="details">
                            <span className="date">{data.upload}</span>
                            <span className="theme">{data.theme}</span>
                            <span className="author">Autor: {data.author}</span>
                        </div>
                        <span className="title">{data.title}</span>
                        <div className="share">
                            <span>Compartilhe: </span>
                        </div>
                    </div>
                    <div className="body">
                        <div className="content">
                            <img src={data.bannerDesktop} alt={data.title} />
                            <span>
                                {data.body.split("\\n\\n").map((paragraph, index) => (
                                    <React.Fragment key={index}>
                                        {paragraph.split("\\n").map((line, lineIndex) => (
                                            <React.Fragment key={lineIndex}>
                                                {line}
                                                <br />
                                            </React.Fragment>
                                        ))}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </span>
                        </div>
                        <div className="another-blogs">
                            <span className="title">Leia mais...</span>
                            {anotherBlogs.map(blog => (
                                <SuggestionCardB
                                    key={blog.slug}
                                    data={{
                                        img: blog.bannerMobile,
                                        link: `/blog/${blog.slug}`,
                                        title: blog.title,
                                        date: blog.upload,
                                        theme: blog.theme,
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                </>
            )}
        </WrapperBlog>
    );
};

export default Blog;
