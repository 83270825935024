import styled from "styled-components";
import HouseAdvertisementSession from "../../components/house_advertisement_session/HouseAdvertisementSession";
import Infos from "../../components/infos/Infos";
import MainBanner from "../../components/main_banner/MainBanner";
import MostSearched from "../../components/most_searched/MostSearched";
import Suggestions from "../../components/suggestions/Suggestions";
import WrapperHome from "./Styles-Home";
import SlideShowBanners from "../../components/slide_show_type_d/SlideShowTypeD";
import Banner2 from "../../imgs/BANNER 02.jpg";
import Banner1 from "../../imgs/BANNER 01.jpg";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from './../../context/AuthContext';


const Home = () => {
  const { userData } = useContext(AuthContext);
  const [filters, setFilters] = useState(null);

  useEffect(() => {
    if (userData && userData.myLocation?.cep !== "") {
      setFilters([{ filterName: "city", value: userData.myLocation.city }]);
    } else {
      setFilters([]);
    }
  }, [userData]);

  return (
    <WrapperHome>
      <MainBanner />
      {filters !== null &&
        <HouseAdvertisementSession
          title="Anúncios Turbinados:"
          filters={[{ filterName: "highlight", value: true }]}
        /> 
      }
      <MostSearched />
      <Infos />
      {filters !== null &&
        <HouseAdvertisementSession 
          title="Sugestões para você" 
          filters={filters} 
        />
      }
      <Suggestions />
      <SlideShowBanners
        items={[
          { banner: Banner1, to: "/anunciar" },
          { banner: Banner2, to: "/buscador" },
        ]}
        visibleItems={1}
      />
    </WrapperHome>
  );
};

export default Home;
